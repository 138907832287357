<template>
  <div class=""></div>
</template>
<script>
export default {
  name: "Scrapper",
  props: {
    name: { default: "" },
    lastname: { default: "" },
    rut: { default: "" },
    email: { default: "" },
    phone: { default: "" },
    income_range: { default: "" },
    submit: { default: "" },
    observation: { default: "" },
    project_name: { default: "" },
    project_id: { default: "" },
    token: { default: null },
    source: { default: null },
    contact: { default: null },
    customer: { default: null },
    utm_campaign: { default: null },
    utm_medium: { default: null },
    utm_source: { default: null },
    utm_term: { default: null },
    utm_content: { default: null }
  },
  data() {
    return {};
  },
  mounted() {
    this.get();
  },
  methods: {
    get: function() {
      console.log("[Mobysuite Form Scrapper] Running");
      document
        .getElementById(this.submit)
        .addEventListener("click", this.sender.bind(this));
    },
    sender: function() {
      let name = document.getElementById(this.name).value;
      let lastname = this.lastname
        ? document.getElementById(this.lastname).value
        : null;
      let rut = document.getElementById(this.rut).value;
      let email = document.getElementById(this.email).value;
      let phone = document.getElementById(this.phone).value;
      let observation = "";
      if (this.observation) {
        let observations = this.observation.split(",");
        observation = observations
          .map(obs => {
            return document.getElementById(obs).value;
          })
          .join(". ");
      }
      let project_name = this.project_name
        ? document.getElementById(this.project_name).value
        : null;
      let project_id = this.project_id
        ? document.getElementById(this.project_id).value
        : null;
      let income_range = this.income_range
        ? document.getElementById(this.income_range).value
        : null;

      //UTM Content
      let utm_general = this.findGetParameter("utm");
      let utm_source = this.utm_source
        ? document.getElementById(this.utm_source).value
        : this.findGetParameter("utm_source");
      let utm_medium = this.utm_medium
        ? document.getElementById(this.utm_medium).value
        : this.findGetParameter("utm_medium");
      let utm_campaign = this.utm_campaign
        ? document.getElementById(this.utm_campaign).value
        : this.findGetParameter("utm_campaign");
      let utm_term = this.utm_term
        ? document.getElementById(this.utm_term).value
        : this.findGetParameter("utm_term");
      let utm_content = this.utm_content
        ? document.getElementById(this.utm_content).value
        : this.findGetParameter("utm_content");

      console.log("[Mobysuite Form Scrapper] Sending Lead");
      if (name && rut && email && phone) {
        this.axios
          .post("api/v1/leads", {
            token: this.token,
            name: name,
            lastname: lastname,
            rut: rut,
            email: email,
            project_id: project_id,
            project_name: project_name,
            phone: phone,
            source: this.source,
            contact: this.contact,
            observation: observation,
            utm_general: utm_general,
            utm_source: utm_source,
            utm_medium: utm_medium,
            utm_campaign: utm_campaign,
            utm_term: utm_term,
            utm_content: utm_content,
            rangoRenta: income_range
          })
          .then(result => {
            console.log("[Mobysuite Form Scrapper]:", result);
          })
          .catch(error => {
            console.log("[Mobysuite Form Scrapper]: Error, ", error);
          });
      } else {
        console.log("[Mobysuite Form Scrapper]: Error, missing data");
      }
    },
    findGetParameter(parameterName) {
      var result = null,
        tmp = [];
      location.search
        .substr(1)
        .split("&")
        .forEach(function(item) {
          tmp = item.split("=");
          if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
      return result;
    }
  }
};
</script>
<style scoped></style>
