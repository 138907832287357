<template>
  <div :style="cssProps" v-if="formSaved">
    <div class="form-success-elements">
      <div class="h1-elements">¡Gracias {{name}}!</div>
      <div class="h2-elements">Hemos recibido tus datos correctamente, nos pondremos en contacto lo antes posible.</div>
      <div class="border-elements"></div>
    </div>
  </div>
  <div :style="cssProps" v-else-if="formSaved == false">
    <div class="form-success-elements">
      <div class="h1-elements">¡Ops :(!</div>
      <div class="h2-elements">Hubo un problema, por favor vuelve a intentar.</div>
      <div class="form-button-container-elements">
        <button class="form-button-element" type="button" @click="reset()">Volver</button>
      </div>
      <div class="border-elements"></div>
    </div>
  </div>
  <div v-else :style="cssProps" class="form-container-element">
    <div class="form-elements">
      <label for="name" class="form-label-element">
        Nombre
      </label>
      <input class="form-input-element" v-model="name" type="text" placeholder="Nombre">
      <div class="error-elements" v-if="$v.name.$error"><small>* Nombre es requerido</small></div>
    </div>
    <div class="form-elements">
      <label for="name" class="form-label-element">
        Apellido
      </label>
      <input class="form-input-element" v-model="lastname" type="text" placeholder="Apellido">
      <div class="error-elements" v-if="$v.lastname.$error"><small>* Apellido es requerido</small></div>
    </div>
    <div class="form-elements">
      <label for="name" class="form-label-element">
        RUT
      </label>
      <input v-model.trim="$v.rut.$model" :state="$v.rut.$dirty ? !$v.rut.$error : null" class="form-input-element" v-model="rut" type="text" placeholder="Rut">
      <div class="error-elements" v-if="$v.rut.$error"><small>* Rut es requerido</small></div>
    </div>
    <div class="form-elements">
      <label for="name" class="form-label-element">
        E-Mail
      </label>
      <input class="form-input-element" v-model="email" type="text" placeholder="Email">
      <div class="error-elements" v-if="$v.email.$error"><small>* E-mail es requerido</small></div>
    </div>
    <div class="form-elements">
      <label for="phone" class="form-label-element">
        Teléfono
      </label>
      <table border="0" style="width: 100%">
        <tr>
          <td>
            <select class="form-input-element" v-model="phoneCode" @change="checkPhone">
              <option v-for="(code, key) in filteredCode" :key="'selCountry'+key" :value="code.dialCode">+{{code.dialCode}}</option>
            </select>
          </td>
          <td>
            <input class="form-input-element" @input="checkPhone" v-model="prevPhone" type="text" placeholder="Teléfono">
          </td>
        </tr>
      </table>
      <div class="error-elements" v-if="$v.phone.$error"><small>* Teléfono es requerido</small></div>
    </div>
    <input v-model="project_name" type="hidden" v-if="project">
    <div class="form-elements" v-else-if="projects">
      <label for="name" class="form-label-element">
        Proyecto
      </label>
      <select class="form-input-element" v-model="project_name">
        <option v-for="project in projects" :value="project.nombreProyecto" :key="project.id" :selected="project.id == 1">{{project.nombreProyecto}} {{project.direccion.comuna ? `(${project.direccion.comuna})` : ''}}</option>
      </select>
    </div>
    <button class="form-button-element" type="button" @click="send()">Enviar</button>
  </div>
</template>
<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import { isRutValid } from "@/validators";
export default {
  name: "Form",
  props: {
      token: { default: null },
      project: { default: null },
      button_color: { default: null },
      button_text: { default: null },
      background_color: { default: null },
      input_color: { default: null },
      source: {default: null},
      contact: {default: null},
      customer: {default: null}
  },
  data() {
    return {
      name: "",
      lastname: "",
      rut: "",
      email: "",
      prevPhone: "",
      phone: "",
      submit: "",
      project_name: "",
      projects: [],
      filteredCode: [
        {name: 'Chile', iso2: 'CL', dialCode: '56'},
      ],
      phoneCode: '56',
      formSaved: null
    };
  },
  validations() {
    return{
      name: { required, minLength: minLength(3) },
      lastname: { required, minLength: minLength(3) },
      rut: { required, RutValid: isRutValid },
      email: { required, email},
      phone: { required, minLength: minLength(9) },
    }
  },
  mounted() {
    this.get_projects()
  },
  methods: {
    get_projects(){
      if(this.customer != null){
        this.axios.get(`https://cotizacion.mobysuite.com/api/v2/quotes/list-projects?real_estate=${this.customer}`).then((result) => {
          this.projects = result.data
          if(!this.project){
            this.project_name = this.projects[0].nombreProyecto
          }
        })
      }
    },
    send() {
      this.$v.$touch();
      if(!this.$v.$invalid){
        this.axios.post("api/v1/leads", {token: this.token ,name: this.name, lastname: this.lastname, rut: this.rut, email: this.email, project_name: this.project_name, phone: this.phone, source: this.source, contact: this.contact}).then((result) => {
          console.log(result)
          this.formSaved = true
        }).catch((error) => {
          console.log(error)
          this.formSaved = false
        })
      }
    },
    checkPhone() {
      if(this.prevPhone){
        if(this.prevPhone.length >= 9){
          var x = this.prevPhone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,3})/);
          this.prevPhone = !x[2] ? x[1] : '' + x[1] + '' + x[2] + (x[3] ? '' + x[3] : '');
          this.phone = "+"+this.phoneCode+this.prevPhone;
        }else{
          this.phone = null;
        }
      }
    },
    reset(){
      this.formSaved = null;
    }
  },
  computed: {
    cssProps() {
      return {
        '--button-color': this.button_color,
        '--button-text': this.button_text,
        '--background-color': this.background_color,
        '--input-color': this.input_color,
      }
    }
  }
};
</script>
<style type="scss" scoped>
  mobysuite-form-scrapper .form-container-element{
    width: 100%;
    height: auto;
    background-color: var(--background-color);
    padding: 20px 0px;
  }
  mobysuite-form-scrapper .form-elements{
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
  mobysuite-form-scrapper .form-label-element{
    font-size: 17px;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--input-color);
  }
  mobysuite-form-scrapper .form-input-element{
    background: transparent;
    width: 100%;
    height: 40px;
    border-radius: 0px;
    padding: 0px;
    font-size: 14px;
    border: 0px;
    color: var(--input-color);
    border-bottom: 1px solid var(--input-color);
    margin-bottom: 10px;
  }
  mobysuite-form-scrapper .form-button-element{
    width: 100%;
    height: 50px;
    border-radius: 0px;
    font-size: 17px;
    border: 1px solid  var(--button-color);
    margin-bottom: 10px;
    padding: 15px 0px;
    background-color: var(--button-color);
    color: var(--button-text);
    cursor: pointer;
  }
  mobysuite-form-scrapper .form-button-element:hover{
    background-color: var(--button-text);
    color: var(--button-color);
  }
  mobysuite-form-scrapper .error-elements{
    color: red;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
  }
  mobysuite-form-scrapper .form-success-elements{
    font-size: 17px;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--background-color);
    background-color: var(--input-color);
    padding: 40px 100px;
    text-align: center;
  }
  mobysuite-form-scrapper .h1-elements{
    font-size: 60px;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--button-color);
    margin-bottom: 20px;
    font-weight: bold;
  }
  mobysuite-form-scrapper .form-button-container-elements{
    width: 50%;
    margin: 0px auto;
    margin-top: 40px;
  }
  mobysuite-form-scrapper .h2-elements{
    font-size: 30px;
    font-weight: 400;
    font-family: Arial, Helvetica, sans-serif;
    color: var(--background-color);
  }
  mobysuite-form-scrapper  .border-elements{
    background: var(--button-color);
    width: 70%;
    height: 4px;
    margin: 40px auto;
  }
  mobysuite-form-scrapper option {
    color: #000000;
  }
</style>
