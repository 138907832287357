import Vue from 'vue'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vuecustomElement from 'vue-custom-element';
import Vuelidate from 'vuelidate'

Vue.config.productionTip = false

const instance = axios.create({
	baseURL: process.env.VUE_APP_API_URL,
	params: {}
});
Vue.use(vuecustomElement);
Vue.use(Vuelidate);
Vue.use(VueAxios, instance);
App.validations = {}
Vue.customElement('mobysuite-form-scrapper', App)
